import React from 'react'
import { useGlobalContext } from 'contexts/GlobalContext'

export default function useEventPageVisited(name: string, properties?: {}) {
  const globalContext = useGlobalContext()

  React.useEffect(() => {
    if (!name) return
    globalContext.analytics?.markPageVisited(name, properties)
  }, [globalContext.analytics, name, properties])
}
