import React from 'react'
import Copy from 'components/Copy'
import Image from 'components/Image'
import Row from 'components/Row'
import Text from 'components/Text'
import GatedResource from 'templates/GatedResource'
import workoutImage from './images/workout.jpg'
import workout2xImage from './images/workout@2x.jpg'

export default function ResourceBoldWhileSeatedChairWorkout() {
  return (
    <GatedResource
      title={
        <>
          Bold while seated:
          <br />A workout you can do from a chair
        </>
      }
      entity="guide"
      resource="Chair arm exercises">
      <Row>
        <Copy>
          <Text element="p">Want to remain seated, but still exercise? No problem!</Text>
          <Text element="p">
            This free guide includes pictures and instructions for a short, seated workout you can
            do from anywhere.
          </Text>
        </Copy>
      </Row>
      <Image src={workoutImage} src2x={workout2xImage} alt="Bold workout" />
    </GatedResource>
  )
}
