import classNames from 'classnames'
import React from 'react'
import type { Component } from 'constants/types'
import styles from './View.module.scss'

export type Props = {
  children: React.ReactNode
  color?: 'blue' | 'grey' | 'white'
  flush?: boolean
  footer?: boolean
} & Component

export default function View({ className, color = 'white', flush, footer, ...props }: Props) {
  return (
    <div
      {...props}
      className={classNames(
        'View',
        styles.this,
        styles[`this---${color}`],
        flush && styles['this---flush'],
        footer && styles['this---footer'],
        className
      )}
    />
  )
}
