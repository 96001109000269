import { SkipNavContent, SkipNavLink } from '@reach/skip-nav'
import classNames from 'classnames'
import React from 'react'
import Footer from 'components/Footer'
import Header, { type HeaderProps } from 'components/Header'
import type { Component } from 'constants/types'
import '@reach/skip-nav/styles.css'
import styles from './Layout.module.scss'

type Props = {
  children: React.ReactNode
  footer?: boolean
  footerProps?: {}
  header?: boolean | React.ReactNode
  headerProps?: HeaderProps
} & Component

export default function Layout({
  children,
  className,
  footer = true,
  footerProps = {},
  header = true,
  headerProps = {},
  ...props
}: Props) {
  return (
    <div {...props} className={classNames('Layout', styles.this, className)}>
      <SkipNavLink />
      {header ? React.isValidElement(header) ? header : <Header {...headerProps} /> : null}
      <SkipNavContent />
      <main className={styles.content}>{children}</main>
      {footer && <Footer {...footerProps} />}
    </div>
  )
}
